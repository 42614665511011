@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply flex flex-col items-center justify-center bg-gradient-to-bl from-neutral-950 via-neutral-800 to-neutral-900  bg-neutral-950 text-red-500 mx-4 lg:mx-48;
}

/* Right bar in navigation */
.lgnavbar::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0.25em;
  height: 100%;
  background: linear-gradient(to bottom, #525252, #737373, #525252);
}

.flip-card {
  perspective: 500px; /* 3D perspective for the flip effect */
  width: 6rem;
  height: 6rem;
  margin: auto;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg); /* Flip on hover */
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  backface-visibility: hidden; /* Prevent the back side from showing */
}

.flip-card-front {
  display: flex;
}

.flip-card-back {
  display: flex;
  background-color: #333;
  color: #fff;
  transform: rotateY(180deg);
}
